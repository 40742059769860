import IntegrationTable from './IntegrationTable';
import PageHeader from '../../components/PageHeader';

const TriggerAdmin = () => {
  return (
    <>
      <PageHeader title="Admin Partner Integration View" />
      <IntegrationTable />
    </>
  );
};

export default TriggerAdmin;
