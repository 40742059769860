import TriggerList from './TriggerList';
import PageHeader from '../../components/PageHeader';
import RouterLink from '../../components/RouterLink';

const TriggerStats = () => {
  return (
    <>
      <PageHeader title="Trigger Statistics" />
      <TriggerList />
    </>
  );
};

export default TriggerStats;
