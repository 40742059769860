import PartnerTable from './PartnerTable';
import PageHeader from '../../components/PageHeader';

const TriggerAdmin = () => {
  return (
    <>
      <PageHeader title="Admin Partners View" />
      <PartnerTable />
    </>
  );
};

export default TriggerAdmin;
