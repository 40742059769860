import ListsTable from './ListsTable';
import PageHeader from '../../components/PageHeader';

const TriggerAdmin = () => {
  return (
    <>
      <PageHeader title="Admin Partner Lists View" />
      <ListsTable />
    </>
  );
};

export default TriggerAdmin;
