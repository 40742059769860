import Pixel from './pages/Pixel';
import Reports from './pages/Reports';
import ListView from './pages/ListView';
import ListManager from './pages/ListManager';
import ListBuilder from './pages/ListBuilder';
import PixelBuilder from './pages/PixelBuilder';
import Integrations from './pages/Integrations';
import CloseCallback from './pages/CloseCallback';
import TriggerManager from './pages/TriggerManager';
import TriggerAdmin from './pages/TriggerAdmin';
import TriggerStats from './pages/TriggerStats';
import TriggerBuilder from './pages/TriggerBuilder';
import AccountSettings from './pages/AccountSettings';
import IntegrationBuilder from './pages/IntegrationBuilder';
import EmailLists from './pages/EmailTemplates';
import PartnerActivity from './pages/PartnerActivity';
import IntegrationAdmin from './pages/IntegrationAdmin';
import ListsAdmin from './pages/ListsAdmin';
import PartnerAdmin from './pages/PartnerAdmin';
import PixelDataTable from './pages/Reports/PixelDataTable';
import ReportsDataTable from './pages/Reports/ReportsDataTable';

import ReportDownloads from './pages/ReportDownloads';
import ReportsAll from './pages/ReportsAll';

import { appPermissions } from './hooks/useAccess';
import EmailEditor from './pages/EmailEditor';
import CampaignBuilder from './pages/CampaignBuilder';
import CampaignLists from './pages/CampaignLists';
import TriggerCampaignForm from './components/CampaignBuilder/TriggerCampaignForm';
import TriggerCampaignEditor from './pages/CampaignBuilder/TriggerCampaignEditor';

import AdminTemplates from './pages/AdminTemplates/index';
import EditAdminTemplate from './components/TemplateCard/EditAdminTemplate';
import TemplateGallery from './pages/TemplateGallery/index';
import DomainLists from './pages/DomainLists';
import DomainBuilder from './pages/DomainBuilder';
import ActivityBuilder from './pages/ActivityBuilder';
import ActivityManager from './pages/ActivityManager';

export const routes = [
  {
    path: '/close',
    component: CloseCallback,
  },
  {
    exact: true,
    path: '/pixel',
    component: Pixel,
  },
  {
    path: '/pixel/add',
    component: PixelBuilder,
  },
  {
    path: '/pixel/:pixelId/edit',
    component: PixelBuilder,
  },
  {
    exact: true,
    path: '/activity-checker',
    component: ActivityManager,
    permission: appPermissions.networkIn,
  },
  {
    exact: true,
    path: '/activity-checker/add',
    component: ActivityBuilder,
    permission: appPermissions.networkIn,
  },
  {
    exact: true,
    path: '/list-manager',
    component: ListManager,
    permission: appPermissions.networkIn,
  },
  {
    exact: true,
    path: '/list-manager/add',
    component: ListBuilder,
    permission: appPermissions.networkIn,
  },
  {
    exact: true,
    path: '/list-manager/:listId/contacts',
    component: ListView,
    permission: appPermissions.networkIn,
  },
  {
    path: '/list-manager/:listId/edit',
    component: ListBuilder,
    permission: appPermissions.networkIn,
  },
  {
    exact: true,
    path: '/trigger-manager',
    component: TriggerManager,
  },
  {
    path: '/trigger-manager/add',
    component: TriggerBuilder,
  },
  {
    path: '/trigger-manager/:triggerId/edit',
    component: TriggerBuilder,
  },
  {
    exact: true,
    path: '/integrations',
    component: Integrations,
  },
  {
    path: '/integrations/add',
    component: IntegrationBuilder,
  },
  {
    path: '/integrations/:integrationId/edit',
    component: IntegrationBuilder,
  },
  {
    exact: true,
    path: '/pixel-reports',
    component: PixelDataTable,
  },
  {
    exact: true,
    path: '/trigger-reports',
    component: ReportsDataTable,
  },
  {
    path: '/reports/all',
    component: ReportsAll,
  },
  {
    path: '/reports/:reportId',
    component: ReportDownloads,
  },
  {
    exact: true,
    path: '/account/settings',
    component: AccountSettings,
  },
  {
    path: '/account/settings/:tabId',
    component: AccountSettings,
  },
  // {
  //   exact: true,
  //   path: '/emails/new',
  //   component: EmailEditor,
  // },
  // {
  //   exact: true,
  //   path: '/emails',
  //   component: EmailLists,
  // },
  // {
  //   path: '/emails/:templateId/edit',
  //   component: EmailEditor,
  // },
  {
    exact: true,
    path: '/campaigns/new',
    component: CampaignBuilder,
  },
  {
    exact: true,
    path: '/campaigns',
    component: CampaignLists,
  },
  {
    path: '/campaigns/:campaignId/edit',
    component: CampaignBuilder,
  },
  {
    path: '/campaigns/:campaignId/integration/edit',
    component: TriggerCampaignEditor,
  },
  {
    exact: true,
    path: '/admin-templates',
    component: AdminTemplates,
    permission: appPermissions.adminRole,
  },
  {
    path: '/admin-templates/:customId/edit',
    component: EditAdminTemplate,
    permission: appPermissions.adminRole,
  },
  {
    path: '/template-gallery',
    component: TemplateGallery,
  },
  // {
  //   exact: true,
  //   path: '/domains',
  //   component: DomainLists,
  // },
  // {
  //   path: '/domain/add',
  //   component: DomainBuilder,
  // },
  {
    exact: true,
    path: '/partner-activity',
    component: PartnerActivity,
    permission: appPermissions.adminRole,
  },
  {
    exact: true,
    path: '/admin-trigger-view',
    component: TriggerAdmin,
    permission: [appPermissions.adminRole, appPermissions.triggerView],
  },
  {
    exact: true,
    path: '/trigger-stats',
    component: TriggerStats,
    permission: [appPermissions.adminRole, appPermissions.triggerView],
  },
  {
    exact: true,
    path: '/admin-integration',
    component: IntegrationAdmin,
    permission: appPermissions.adminRole,
  },
  {
    exact: true,
    path: '/admin-lists',
    component: ListsAdmin,
    permission: appPermissions.adminRole,
  },
  {
    exact: true,
    path: '/admin-partner',
    component: PartnerAdmin,
    permission: appPermissions.adminRole,
  },
];
