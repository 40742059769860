import TriggerList from './TriggerList';
import PageHeader from '../../components/PageHeader';

const TriggerAdmin = () => {
  return (
    <>
      <PageHeader title="Admin Partner Trigger View" />
      <TriggerList />
    </>
  );
};

export default TriggerAdmin;
