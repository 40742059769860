import Link from '@material-ui/core/Link';
import Stack from '@material-ui/core/Stack';
import Divider from '@material-ui/core/Divider';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import {
  BrowserRouter,
  Redirect,
  Route,
  Switch,
  useLocation,
} from 'react-router-dom';

import Header from '../Header';
import Spinner from '../Spinner';
import { useStyles } from './styles';
import { routes } from '../../routes';
import Navigation from '../Navigation';
import StatusPage from '../StatusPage';
import ScrollToTop from '../ScrollToTop';
import { useAccess } from '../../hooks/useAccess';

const App = () => {
  const classes = useStyles();
  const { hasPermission, isLoading } = useAccess();
  const location = useLocation();
  const specialEndpoints = new Set([
    '/admin-trigger-view',
    '/trigger-stats',
    '/admin-templates',
    '/partner-activity',
    '/admin-integration',
    '/admin-lists',
    '/admin-partner',
  ]);

  return (
    <div className={classes.root}>
      <Header />
      <Navigation />
      <main
        className={classes.content}
        style={{
          backgroundColor: specialEndpoints.has(location.pathname)
            ? '#E8C94D'
            : '',
        }}
      >
        <Toolbar />
        <div
          className={classes.container}
          style={{
            backgroundColor: specialEndpoints.has(location.pathname)
              ? '#E8C94D'
              : '',
          }}
        >
          <Switch>
            <Route exact path="/">
              <Redirect to="/pixel" />
            </Route>
            <Route exact path="/network-manager">
              <Redirect to="/list-manager" />
            </Route>
            {routes.map(route => {
              const hasRequiredPermissions = Array.isArray(route.permission)
                ? route.permission.some(permission => hasPermission(permission))
                : hasPermission(route.permission);

              return (
                <Route key={route.path} path={route.path} exact={!!route.exact}>
                  {hasRequiredPermissions ? (
                    <route.component />
                  ) : (
                    <>
                      {isLoading ? (
                        <Spinner fixed />
                      ) : (
                        <StatusPage
                          text="401"
                          description="You don't have permissions to see that page."
                        />
                      )}
                    </>
                  )}
                </Route>
              );
            })}

            <Route path="/401">
              <StatusPage
                text="401"
                description="You don't have permissions to see that page."
              />
            </Route>
            <Route>
              <StatusPage
                text="404"
                description="This page could not be found."
              />
            </Route>
          </Switch>
        </div>
        <div className={classes.grow} />
        <footer className={classes.footer}>
          <Stack
            direction="row"
            divider={<Divider orientation="vertical" flexItem />}
            spacing={2}
          >
            <Typography variant="body2">
              © {new Date().getFullYear()} Inbox Mailers.
            </Typography>
            <Link
              variant="body2"
              href="https://www.inboxmailers.com/privacy-policy/"
              target="_blank"
            >
              Privacy Policy
            </Link>
            <Link
              variant="body2"
              href="https://inboxmailers.com/terms-of-service/"
              target="_blank"
            >
              Terms of Service
            </Link>
          </Stack>
        </footer>
      </main>
    </div>
  );
};

const AppWithRouter = () => (
  <BrowserRouter>
    <ScrollToTop />
    <App />
  </BrowserRouter>
);

export default AppWithRouter;
